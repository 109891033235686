import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import useSiteImages from '../hooks/use-site-images'
import { colors, media } from '../tokens'
import '../utils/FontAwesome'
import {Container, Row, Col } from 'react-bootstrap'
import NewsLetterForm from './NewsLetterForm'
import SocialButton from './SocialButton'

const FooterWrapper = styled.footer`
  text-align: left;
  padding-top: 30px;
  padding-bottom: 50px;
  background-color: ${colors.primary};
  color: ${colors.textLightest};
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;

  & nav {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    max-width: 900px;
    margin: 0 auto;

    .footer-col {
      flex: 1 auto;
      display: inline-flex;
      flex-direction: column;
      margin-bottom: 1em;
      padding-right: 1em;
      width: 225px;
    }
  }

  & a {
    color: ${colors.textLightest};
    font-weight: bold;

    &:hover {
      color: ${colors.dypPurpleLight};
      /* border-bottom: 1px dotted ${colors.textLightestHover}; */
    }
  }

  .footer-col > p {
    margin: 0;
  }
  .footer-title  {
    margin: 0 0 1rem;
    padding-left 15px;
  }
  .footer-title > a {
    z-index: 2;
  }
  .footer-title > a:hover {
    color: ${colors.dypBeigeLight};
    text-decoration: none;
  }
  .footer-title > a::before {
    transition: all ease 0.8s;
    Content: "";
    position: absolute;
    display: block;
    z-index: -1;
    width: 110px;
    height: 8px;
    margin: 28px 0px 0px 0px;
    background-color: ${colors.dypBlue};
  }
  .footer-title > a:hover::before {
    box-shadow: inset 110px 0 0 0 ${colors.dypPurpleLight};
  }

  .footer-item {
    padding: 0.25rem 0;
    color: ${colors.textLightest};
  }

  .footer-heart {
    color: ${colors.heartFooter};
  }

  .footer-item-text {
    padding: 0.1rem 0;
    color: ${colors.textLightest};
  }

  .footer-header {
    order: 1;
    margin: 0 0.25rem;
    margin-right: 0.25rem;
    padding: 0.25rem;
  }

  @media ${media.maxSmall} {
    .footer-col {
      margin-left: 2em;
      /*width: 100%;
      align-content: center;
      align-items: center;*/
    }
  }

  .footer-copyright{
    font-size: x-small;
    align-self: center;
    text-align: center;
  }
  .footer-copyright-one{
    color: ${colors.dypBlueLight};
  }
  .footer-copyright-two{
    color: ${colors.dypPurpleLight};
  }

  .colContainer{
    text-align: center;
  }

  .colContainerInBlock{
    display: inline-block;
    text-align: left;
  }

  .logo-label{
    font-size: large;
  }
`

const JustifyRow = styled(Row)`
  justify-content: space-around;
`

const FooterSocial = (props) => {
  const {
    socialTwitter,
    socialYoutube,
    socialUnity,
    socialReddit,
    socialDiscord,
    socialTikTok,
    socialFacebook,
    socialInstagram,
    socialTwitch,
    socialLinkedin
  } = useSiteMetadata()

  return (
    <Container>
      <JustifyRow>
        <Col xs={4} md={2}><SocialButton link={socialTwitter} icon={['fab', 'twitter']}/></Col>
        <Col xs={4} md={2}><SocialButton link={socialYoutube} icon={['fab', 'youtube']}/></Col>
        <Col xs={4} md={2}><SocialButton link={socialReddit} icon={['fab', 'reddit']}/></Col>
        <Col xs={4} md={2}><SocialButton link={socialDiscord} icon={['fab', 'discord']}/></Col>
        <Col xs={4} md={2}><SocialButton link={socialUnity} icon={['fab', 'unity']}/></Col>
        <Col xs={4} md={2}><SocialButton link={socialTikTok} icon={['fab', 'tiktok']}/></Col>
        <Col xs={4} md={2}><SocialButton link={socialFacebook} icon={['fab', 'facebook']}/></Col>
        <Col xs={4} md={2}><SocialButton link={socialInstagram} icon={['fab', 'instagram']}/></Col>
        <Col xs={4} md={2}><SocialButton link={socialTwitch} icon={['fab', 'twitch']}/></Col>
        <Col xs={4} md={2}><SocialButton link={socialLinkedin} icon={['fab', 'linkedin']}/></Col>
      </JustifyRow>
    </Container>
  )
}

const FooterLogo = styled.a`
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  text-align:center;
  width: 300px;
`

const Footer = (props) => {
  const {hideLogo} = props;
  const { authorName, websiteHost, footerLinks, footerLinksIcon } = useSiteMetadata()
  const iconSrc = footerLinksIcon
    ? useSiteImages(footerLinksIcon).fixed
    : null

  const FooterItem = ({ item }) => {
    if (item.url.startsWith('/')) {
      return (
        <div className="footer-item d-flex justify-content-center justify-content-sm-start">
          <Link className="footer-link" to={item.url}>
            {item.label}
          </Link>
        </div>
      )
    }
    return (
      <div className="footer-item d-flex justify-content-center justify-content-sm-start">
        <a className="footer-link" href={item.url}>
          {item.label}
        </a>
      </div>
    )
  }

  const FooterColumn = ({ column }) => {
    return (
      <Col md={3} sm={6}>
        <div className="colContainer">
          <div className="colContainerInBlock">
            <h2 className="footer-title d-flex justify-content-center justify-content-sm-start" key={column.sectionName}>
              <Link to={column.url}>{column.sectionName}</Link>
            </h2>
            {column.links.map((item, i) => {
              return <FooterItem item={item} key={`footer-column-item-${i}`} />
            })}
          </div>
        </div>
      </Col>
    )
  }

  return (
    <FooterWrapper>
      {!hideLogo && <FooterLogo href="/">
        <img src={iconSrc.src} alt="dypsloom-logo"></img>
        <p className="logo-label">Never Stop Dreaming</p>
        </FooterLogo>}
      <NewsLetterForm/>
      <br/>
      <FooterSocial/>
      <Container>
        <Row className="justify-content-xs-space-between">
          {footerLinks.map((column, i) => {
            return <FooterColumn column={column} key={`footer-column-${i}`} />
          })}
        </Row>
      </Container>
      <br/>
      <p className="footer-copyright">
        © 2020 ALL RIGHTS RESERVED,
        <span className="footer-copyright-one"> DYPSLOOM </span>
        CREATED BY
        <span className="footer-copyright-two"> SANTIAGO RUBIO</span>
      </p>
    </FooterWrapper>
  )
}

export default Footer
