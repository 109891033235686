import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetadata = () => {
  const result = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteTitle
          siteUrl
          supportEmail
          forumUrl
          siteCover
          authorName
          siteDescription
          siteKeywords
          socialTwitter
          socialDiscord
          socialUnity
          socialYoutube
          socialReddit
          socialFacebook
          socialTikTok
          socialInstagram
          socialTwitch
          socialLinkedin
          defaultLang
          members{
            name
            userName
            title
            image
            description
          }
          websiteHost {
            name
            url
          }
          headerTitle
          headerLinksIcon
          headerLinks {
            label
            url
            links {
              label
              url
            }
          }
          footerLinksIcon
          footerLinks {
            sectionName
            url
            links {
              label
              url
            }
          }
        }
      }
    }
  `)
  return result.site.siteMetadata
}

export default useSiteMetadata
