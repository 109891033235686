import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import { colors } from '../tokens'
import '../utils/FontAwesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.div`
    height: 75px;
    width: 75px;
    margin: 5px auto;

  /* Style all font awesome icons */
  .fa {
    transition: background 0.5s;
    font-size: 34px;
    width: 75px;
    height: 75px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    border: 2px solid ${colors.dypBlueLight};

    display: flex;
    align-items: center; 
    justify-content: center;
    text-align: center; 
  }

  /* Add a hover effect if you want */
  .fa:hover {
    color: ${colors.primary};
    background: ${colors.dypBlueLight};
  }
`

const SocialButton = props => {
    const { link, icon } = props 
  
    return (
      <Wrapper>
          <a href={link} className="fa"><FontAwesomeIcon icon={icon} /></a>
      </Wrapper>
    )
  }
  
  export default SocialButton