// 50 shades of grey generator
// https://javisperez.github.io/tailwindcolorshades/#/?%233E4047=3E4047&tv=1
const colors = {
  grey100: '#ECECED',
  grey200: '#CFCFD1',
  grey300: '#B2B3B5',
  grey400: '#78797E',
  grey500: '#3E4047',
  grey600: '#383A40',
  grey700: '#25262B',
  grey800: '#1C1D20',
  grey900: '#131315',

  white: '#ffffff',
  yellow: '#ffdc4e',
  lightYellow: '#f9e892',
  lightBlue: '#697980',

  dypBlueLight: '#7fd4e3',
  dypBlue: '#4b7880',
  dypBlueDark: '#1e3033',

  dypPurpleLight: '#9D80F7',
  dypPurple: '#67559e',
  dypPurpleDark: '#27213b',

  dypBeigeLight: '#edecd5',
  dypBeige: '#c9c8af',
  dypBeigeDark: '#828172',

  dypGreyDark:'#1d292b',
}

export default {
  ...colors,
  textLightest: colors.white,
  textLightestHover: colors.grey200,
  textLight: colors.dypBlue,
  primary: colors.dypGreyDark,
  primaryAlpha: `rgba(30, 48, 51, 0.85)`,
  text: '#edecd5' /*colors.grey500*/,
  textDark: colors.grey500 /*colors.grey500*/,
  background: '#40494d'/*'#454c5e'*/,
  backgroundArticle: colors.white,
  heartFooter: 'red',
  links: colors.yellow,
  backgroundSelection: colors.yellow,
  highlight_code_oneline: '#fff9d9',
  highlight_code_bg: colors.yellow,
  highlight_code_marker: colors.yellow,
  highlight_code_linebg: '#022a4b',
  socialMediaCardFilter: '#437abf', // #8f43bf
  postMetadata: colors.lightBlue,

  
  // testing
  // primary: `#6556B3`,
  // primaryAlpha: `#6556B3cc`,
}
