import React from 'react'
import styled from 'styled-components'
import { colors } from '../tokens'
import '../utils/FontAwesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Form, Container, Button} from 'react-bootstrap'

const NewsLetterWrapper = styled.div`

  .newsletter-signup-form{
    display: flex;
    margin: 0 20vw;
    margin-bottom: 1em;
    align-content: center;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .newsletter-signup-label{
    
  }

  .newsletter-signup-text-box{
    width: 100%;
    height: 40px;
    background: ${colors.dypBeige};
    border-radius: 20px;
    position: relative;
  }

  .newsletter-signup-input{
    width: 90%;
    background: none;
    border: 0;
    padding: 0 30px;
    outline: 0;
    font-size: 20px;
    padding-right: 60px;
    justify-content: : center;
    height: 40px; 
    line-height: 40px;
      
      &:focus {
        border-color: $gray;
      }
  }

  .newsletter-signup-button{
    background: ${colors.dypBlueLight};
    border:0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    right: 5px;
    top: 5px; 
    outline: none;
    cursor: pointer;
    position: absolute;

    display: flex;
    align-items: center; 
    justify-content: center;
    text-align: center;
    font-size: 20px;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: ${colors.dypBlueLight};
    background-color: ${colors.dypBlueLight};
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: ${colors.dypBlue};
    border-color: ${colors.dypBlue};
}

    .hidden{
        position: absolute; 
        left: -5000px;
    }
`
/*
       </style>
       <div id="mc_embed_signup">
       <form action="https://dypsloom.us10.list-manage.com/subscribe/post?u=e58d70ae0566333e8af11af31&amp;id=6dad625114" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
           <div id="mc_embed_signup_scroll">
           <h2>Subscribe</h2>
       <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
       <div class="mc-field-group">
           <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
       </label>
           <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
       </div>
       <div class="mc-field-group">
           <label for="mce-FNAME">First Name </label>
           <input type="text" value="" name="FNAME" class="" id="mce-FNAME">
       </div>
       <div class="mc-field-group">
           <label for="mce-LNAME">Last Name </label>
           <input type="text" value="" name="LNAME" class="" id="mce-LNAME">
       </div>
       <div class="mc-field-group size1of2">
           <label for="mce-BIRTHDAY-month">Birthday </label>
           <div class="datefield">
               <span class="subfield monthfield"><input class="birthday " type="text" pattern="[0-9]*" value="" placeholder="MM" size="2" maxlength="2" name="BIRTHDAY[month]" id="mce-BIRTHDAY-month"></span> / 
               <span class="subfield dayfield"><input class="birthday " type="text" pattern="[0-9]*" value="" placeholder="DD" size="2" maxlength="2" name="BIRTHDAY[day]" id="mce-BIRTHDAY-day"></span> 
               <span class="small-meta nowrap">( mm / dd )</span>
           </div>
       </div><div class="mc-field-group input-group">
           <strong>Assets </strong>
           <ul><li><input type="radio" value="Assets" name="ASSETS" id="mce-ASSETS-0"><label for="mce-ASSETS-0">Assets</label></li>
       </ul>
       </div>
       <div class="mc-field-group input-group">
           <strong>Games </strong>
           <ul><li><input type="radio" value="Games" name="GAMES" id="mce-GAMES-0"><label for="mce-GAMES-0">Games</label></li>
       </ul>
       </div>
           <div id="mce-responses" class="clear">
               <div class="response" id="mce-error-response" style="display:none"></div>
               <div class="response" id="mce-success-response" style="display:none"></div>
           </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
           <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_e58d70ae0566333e8af11af31_6dad625114" tabindex="-1" value=""></div>
           <div class="clear"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></div>
           </div>
       </form>
       </div>
       <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[7]='ASSETS';ftypes[7]='radio';fnames[6]='GAMES';ftypes[6]='radio';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
       <!--End mc_embed_signup-->

*/

const NewsLetterForm = () => {
    return (
        <Container><NewsLetterWrapper>
            <Form action="https://dypsloom.us10.list-manage.com/subscribe/post?u=e58d70ae0566333e8af11af31&amp;id=6dad625114" 
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <Form.Row className="justify-content-center">
                    <Form.Group>
                        <Form.Label>Register to the Dypsloom Newsletter for</Form.Label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Form.Group>
                    <Form.Row>
                        <Form.Check xs={6}
                            defaultChecked
                            type="switch"
                            id="games-switch"
                            label="Games"
                            value="Games" name="GAMES" id="mce-GAMES-0"
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Form.Check xs={6}
                            defaultChecked
                            type="switch"
                            id="assets-switch"
                            label="Assets"
                            value="Assets" name="ASSETS" id="mce-ASSETS-0"
                        />
                    </Form.Row>
                </Form.Row>
                <Form.Group className="newsletter-signup-text-box">
                    <Form.Control /*type="email"*/ placeholder="Enter email" className="newsletter-signup-input" name="EMAIL" /*class="required email"*/ id="mce-EMAIL"/>
                    <Button type="submit" className="newsletter-signup-button" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" >
                        <FontAwesomeIcon icon='arrow-right' className="newsletter-signup-button-fa"/>
                    </Button>
                    <div className="hidden" aria-hidden="true"><input type="text" name="b_e58d70ae0566333e8af11af31_6dad625114" tabindex="-1"/></div>
                    <div id="mce-responses" class="clear">
                        <div class="response" id="mce-error-response" /*style="display:none"*/></div>
                        <div class="response" id="mce-success-response" /*style="display:none"*/></div>
                    </div>
                </Form.Group>
            </Form>
        </NewsLetterWrapper></Container>
    )
}

export default NewsLetterForm