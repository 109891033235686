import { library } from '@fortawesome/fontawesome-svg-core'
import { faUnity, faDiscord, faFacebook, faTwitter, faYoutube, faReddit, faPatreon, faWindows, faItchIo, faHtml5, faInstagram, faLinkedin, faTwitch, faTiktok, faSteam} from '@fortawesome/free-brands-svg-icons'
import { faArrowRight, faChevronRight,faChevronLeft, faBook, faComments, faVideo, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'



library.add(
    faFacebook,
    faUnity,
    faDiscord,
    faTwitter,
    faYoutube,
    faReddit,
    faPatreon,
    faInstagram,
    faLinkedin,
    faTwitch,
    faSteam,
    faTiktok,
    faWindows,
    faItchIo,
    faHtml5,
    faArrowRight,
    faChevronRight,
    faChevronLeft,
    faBook,
    faComments,
    faVideo,
    faCloudDownloadAlt,
    faEnvelope)